<template>
  <Card title="考核评价">
    <div style="padding-top: 16px"></div>
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="计划名称">
            <a-textarea
              :auto-size="{ minRows: 3 }"
              v-decorator="[
                'name',
                {
                  initialValue: text.name,
                },
              ]"
              disabled
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="考核时间">
            <a-range-picker
              style="width: 100%"
              :placeholder="['起始年月','结束年月']"
              v-decorator="[
                              'time',
                              {
                                initialValue: text.time
                              },
                            ]"
              format="YYYY-MM-DD"
              disabled
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="备注">
            <a-textarea
              :auto-size="{ minRows: 3 }"
              v-decorator="[
                'remark',
                {
                  initialValue: text.remark,
                },
              ]"
              disabled
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="人员安排">
            <Assess :list="userList" @change="onAssessChange" />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </Card>
</template>

<script>
import Assess from "./components/Assess";
import { assess } from "@/api/personnel-assess";
import moment from "moment";

export default {
  components: {
    Assess,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      userList: [],
      text: {},
      saveLoading: false,
    };
  },

  mounted() {
    const text = this.$route.query;
    if (!text.id) {
      return;
    }

    this.userList = text.userList.map((item, index) => {
      let completeRatio;
      if (item.taskNumPlan === 0) {
        completeRatio = "0%";
      } else {
        completeRatio =
          parseInt((item.taskNumDone / item.taskNumPlan) * 100) + "%";
      }

      return {
        ...item,
        key: index + 1,
        completeRatio,
      };
    });

    this.text = {
      ...text,
      time: [moment(text.timeBegin), moment(text.timeEnd)],
    };
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },
    onAssessChange(val) {
      this.userList = val;
      console.log(this.userList);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let timeBegin, timeEnd;
          if (Array.isArray(values.time) && values.time.length === 2) {
            timeBegin = values.time[0].format("YYYY-MM-DD");
            timeEnd = values.time[1].format("YYYY-MM-DD");
          }

          this.saveLoading = true;
          assess({
            id: this.text.id,
            ...values,
            timeBegin,
            timeEnd,
            time: undefined,

            userList: this.userList.map((item) => {
              return {
                ...item,
                completeRatio: undefined,
              };
            }),
          })
            .then(() => {
              this.$router.go(-1);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  padding-bottom: 24px;
}
</style>
