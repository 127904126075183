<template>
  <div>
    <a-table :dataSource="list" :pagination="false" bordered :columns="columns" rowKey="key">
      <template slot="key" slot-scope="text">{{text}}</template>

      <template slot="userName" slot-scope="text">{{ text }}</template>

      <template slot="taskNumPlan" slot-scope="text">{{ text }}</template>

      <template slot="taskNumDone" slot-scope="text">{{ text }}</template>

      <template slot="completeRatio" slot-scope="text">{{ text }}</template>

      <template slot="average" slot-scope="text">{{ text }}</template>

      <template slot="appraisal" slot-scope="text, record">
        <a-select
          style="width: 100%"
          placeholder="请选择"
          :value="record.appraisal"
          @change="(val)=>{
                    assessChange(val,record)
                  }"
        >
          <a-select-option
            v-for="item in assessList"
            :key="item.value"
            :value="item.value"
          >{{ item.userName }}</a-select-option>
        </a-select>
      </template>
    </a-table>

    <div style="padding-top: 8px;color: #999;font-size: .8em">
      <div>60分以下差，60-80分一般，80-90分良好，90-100分优秀。</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "key",
          align: "center",
          scopedSlots: { customRender: "key" },
        },

        {
          title: "姓名",
          dataIndex: "userName",
          align: "center",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "指派任务数量",
          dataIndex: "taskNumPlan",
          align: "center",
          scopedSlots: { customRender: "taskNumPlan" },
        },
        {
          title: "实际完成数量",
          dataIndex: "taskNumDone",
          align: "center",
          scopedSlots: { customRender: "taskNumDone" },
        },

        {
          title: "任务完成率",
          dataIndex: "completeRatio",
          align: "center",
          scopedSlots: { customRender: "completeRatio" },
        },
        {
          title: "平均得分",
          dataIndex: "average",
          align: "center",
          scopedSlots: { customRender: "average" },
        },
        {
          title: "评价",
          dataIndex: "appraisal",
          align: "center",
          scopedSlots: { customRender: "appraisal" },
        },
      ],

      assessList: [
        {
          value: "优秀",
          userName: "优秀",
        },
        {
          value: "良好",
          userName: "良好",
        },
        {
          value: "一般",
          userName: "一般",
        },
        {
          value: "差",
          userName: "差",
        },
      ],
    };
  },

  methods: {
    assessChange(val, record) {
      record.appraisal = val;
      this.$emit("change", this.list);
    },
  },
};
</script>
